$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);
$theme-six: var(--theme-six);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#footer-container {
  position: relative;
  height: auto;
  width: 100%;
  // background-image: url('../../../Images/Footer/map-2.svg');
  background-color: $footer-one;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 0rem 0rem;
  margin-bottom: 0;

  #footer-top-container {
    display: flex;
    justify-content: center;
    position: relative;
    height: 8rem;
    width: 100%;
    padding: 0 3.5rem;
    box-sizing: border-box;

    #footer-top-container-box {
      position: relative;
      display: flex;
      height: 100%;
      width: 90%;
      overflow-x: hidden;

      .footer-top-box {
        display: flex;
        flex-flow: row wrap;
        position: absolute;
        top: 0;
        opacity: 0;
        height: 100%;
        width: 0;
        color: white;
        box-sizing: border-box;
        text-decoration: none;

        &:hover {
          .footer-top-box-left {
            .footer-top-box-left-icon {
              filter: invert(10%);
            }
          }
        }
  
        .footer-top-box-left {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 25%;
          
          .footer-top-box-left-icon {
            height: 4rem;
            width: 4rem;
            border-radius: 50%;
            transition: filter 0.3s ease-in-out;
          }
        }
        .footer-top-box-right {
          margin-top: 2rem;
          height: 5rem;
          width: 75%;
  
          .footer-top-box-right-title {
            font-size: 1.1rem;
            width: 100%;
          }
          .footer-top-box-right-subtitle {
            font-size: 2rem;
            width: 100%;
            cursor: pointer;
            font-family: fontLight;
          }
        }
  
        &:first-child {background-color: $theme-two;left: 0;}
        &:nth-child(2) {background-color: $theme-three;left: 33.33%;}
        &:last-child {background-color: $theme-four;left: 66.66%;}
      }

      .footer-top-mount {
        animation-duration: 0.8s;
        animation-fill-mode: both;

        &:nth-child(1) {animation-delay: 0s;animation-name: footer-top-mount-one;}
        &:nth-child(2) {animation-delay: 0.3s;animation-name: footer-top-mount-two;}
        &:nth-child(3) {animation-delay: 0.6s;animation-name: footer-top-mount-three;}
      }

      @keyframes footer-top-mount-one {100% {width: 33.33%;opacity: 1;}}
      @keyframes footer-top-mount-two {100% {width: 33.33%;opacity: 1;}}
      @keyframes footer-top-mount-three {100% {width: 33.33%;opacity: 1;}}
    }
  }

  #footer-mid-container {
    display: flex;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 5rem 8rem;
    box-sizing: border-box;

    .footer-mid-box {
      height: 18rem;
      width: 20rem;
      padding-right: 3rem;
      box-sizing: border-box;

      &:hover {
        .footer-mid-title {
          &::after {
            width: 130%;
          }
        }
      }
  
      .footer-mid-title {
        position: relative;
        font-size: 1.3rem;
        width: fit-content;
        padding-bottom: 1rem;
        color: #eee;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          bottom: 0.2rem;
          left: 0;
          height: 1px;
          width: 0;
          background-color: $footer-two;
          transition: width 0.3s ease-in-out;
        }
      }
      .footer-mid-bottom-two {
        display: flex;
        justify-content: flex-start;
        height: 10rem;
        width: 350%;
        font-size: 1.1rem;
        color: grey;
        padding: 0;
        box-sizing: border-box;

        .footer-mid-bottom-box {
          height: 100%;
          width: 25%;

          .footer-mid-bottom-elements {
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
              color: white;
            }
          }
        }
      }
    }

    #footer-mid-one {
      .footer-mid-bottom {
        height: 10rem;
        width: 90%;
        font-size: 1.1rem;
        color: grey;
        padding: 1rem 0rem 0 0;
        box-sizing: border-box;
        cursor: default;

        .footer-mid-one-box {
          display: flex;
          position: relative;
          height: 3rem;
          width: 100%;
          cursor: pointer;
          text-decoration: none;

          &:nth-child(1) {
            padding-bottom: 1rem;
            .footer-mid-one-right {
              text-decoration: none;
              color: grey;
              &::before {
                top: 0.3rem;
                background-image: url('../../../Images/Footer/location.svg');
              }
            }
          }
          &:nth-child(2) {
            .footer-mid-one-right {
              color: grey;
              text-decoration: none;
              &::before {
                background-image: url('../../../Images/Footer/call.svg');
              }
            }
          }
          &:nth-child(3) {
            .footer-mid-one-right {
              color: grey;
              text-decoration: none;
              &::before {
                background-image: url('../../../Images/Header/whatsapp.svg');
              }
            }
          }
          &:nth-child(4) {
            .footer-mid-one-right {
              &::before {
                background-image: url('../../../Images/Footer/mail.svg');
              }
            }
          }

          .footer-mid-one-right {
            font-family: fontReg;
            font-size: 1.1rem;
            position: relative;

            &:hover {
              &::before {
                filter: invert(0%);
              }
            }

            &::before {
              content: '';
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0; left: -2.5rem;
              height: 1.5rem;
              width: 1.5rem;
              background-size: contain;
              background-position: center;
              filter: invert(45%);
              transition: filter 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }

  #footer-bottom-container {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem 8rem;
    font-size: 0.95rem;
    color: $border-one;
    background-color: $footer-two;
    box-sizing: border-box;
    cursor: default;

    span a {
      text-decoration: none;
      color: $border-one;
      transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  
  #footer-container {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    padding: 1rem 1rem 3rem 1rem;
    background-color: $footer-one;
    box-sizing: border-box;

    #footer-top-container {
      position: relative;
      height: 18rem;
      width: 100%;
      padding: 0;
      box-sizing: border-box;
  
      #footer-top-container-box {
        position: relative;
        width: 100%;
        overflow: hidden;

        .footer-top-box { 
          display: flex;
          flex-flow: row wrap;
          position: absolute;
          left: -100%;
          opacity: 0;
          height: 6rem;
          width: 100%;
          color: white;
          box-sizing: border-box;
    
          .footer-top-box-left {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 25%;
            
            .footer-top-box-left-icon {
              height: 3rem;
              width: 3rem;
              border-radius: 50%;
            }
          }
          .footer-top-box-right {
            margin-top: 1.5rem;
            height: 5rem;
            width: 75%;
    
            .footer-top-box-right-title {
              font-size: 1rem;
              width: 100%;
            }
            .footer-top-box-right-subtitle {
              font-size: 1.75rem;
              width: 100%;
              cursor: pointer;
              font-family: fontLight;
            }
          }
    
          &:first-child {background-color: $theme-two;top: 0;left: -100%;}
          &:nth-child(2) {background-color: $theme-three;top: 6rem;left: -100%;}
          &:last-child {background-color: $theme-four;top: 12rem;left: -100%;}
        }
  
        .footer-top-mount-p {
          animation-name: footer-top-mount-p;
          animation-duration: 0.8s;
          animation-fill-mode: both;
  
          &:nth-child(1) {animation-delay: 0s;}
          &:nth-child(2) {animation-delay: 0.3s;}
          &:nth-child(3) {animation-delay: 0.6s;}
        }
  
        @keyframes footer-top-mount-p {
          100% {
            left: 0;
            opacity: 1;
          }
        }
      }
    }

    #footer-mid-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: auto;
      // background-color: red;
      width: 100%;
      padding: 2rem 1.5rem 5rem 1rem;
      box-sizing: border-box;
  
      .footer-mid-box {
        height: auto;
        width: 20rem;
        padding-right: 3rem;
        box-sizing: border-box;
  
        &:hover {
          .footer-mid-title {
            &::after {
              width: 130%;
            }
          }
        }
    
        .footer-mid-title {
          position: relative;
          font-size: 1.3rem;
          width: fit-content;
          margin-top: 2rem;
          padding-bottom: 1rem;
          color: #eee;
          cursor: pointer;
  
          &::after {
            content: '';
            position: absolute;
            bottom: 0.2rem;
            left: 0;
            height: 2px;
            width: 0;
            background-color: $footer-two;
            transition: width 0.3s ease-in-out;
          }
        }
        .footer-mid-bottom-two {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: auto;
          width: 100%;
          font-size: 1.1rem;
          color: $border-one;
          padding: 0;
          box-sizing: border-box;
  
          .footer-mid-bottom-box {
            height: 100%;
            width: 100%;
  
            .footer-mid-bottom-elements {
              padding: 0.5rem 0rem;
              color: grey;
              cursor: pointer;
              transition: color 0.3s ease-in-out;
  
              &:hover {
                color: white;
              }
            }
          }
        }
      }
  
      #footer-mid-one {
        .footer-mid-bottom {
          height: 15rem;
          width: 90%;
          font-size: 1.1rem;
          color: $border-one;
          padding: 1rem 0rem 0 2.5rem;
          box-sizing: border-box;
          cursor: default;
  
          .footer-mid-one-box {
            display: flex;
            position: relative;
            height: 3rem;
            width: 100%;
  
            &:nth-child(1) {
              padding-bottom: 1rem;
              .footer-mid-one-right {
                &::before {
                  top: 0.3rem;
                  background-image: url('../../../Images/Footer/location.svg');
                }
              }
            }
            &:nth-child(2) {
              .footer-mid-one-right {
                &::before {
                  background-image: url('../../../Images/Footer/call.svg');
                }
              }
            }
            &:nth-child(3) {
              .footer-mid-one-right {
                &::before {
                  background-image: url('../../../Images/Header/whatsapp.svg');
                }
              }
            }
            &:nth-child(4) {
              .footer-mid-one-right {
                color: grey;
                &::before {
                  background-image: url('../../../Images/Footer/mail.svg');
                }
              }
            }
  
            .footer-mid-one-right {
              font-family: fontReg;
              font-size: 1.1rem;
              position: relative;
  
              &:hover {
                &::before {
                  filter: invert(0%);
                }
              }
  
              &::before {
                content: '';
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0; left: -2.5rem;
                height: 1.5rem;
                width: 1.5rem;
                background-size: contain;
                background-position: center;
                filter: invert(45%);
                transition: filter 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }
    
    #footer-bottom-container {
      display: flex;
      flex-flow: row wrap;
      position: absolute;
      bottom: 0;
      // height: 5rem;
      width: 100%;
      padding: 1rem 2rem;
      font-size: 0.95rem;
      color: $border-one;
      background-color: $footer-two;
      box-sizing: border-box;

      span {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);

$shadow: var(--shadow);
$shadow-two: var(--shadow-two);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#inc-container {
  height: auto;
  min-height: calc(100vh - 9rem);
  width: 100%;
  // margin-top: 9rem;
  padding: 2rem 10rem;
  background-color: $bg;
  box-sizing: border-box;

  #inc-header-cover {
    display: none;
  }

  #inc-top-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: auto;
    width: 100%;
    padding: 2rem 0;
    margin-bottom: 1rem;
    // border-bottom: 2px solid $theme-four;

    .inc-top-menu {
      position: relative;
      margin-right: 1rem;
      padding: 0.7rem 1rem;
      border-radius: 0.5rem;
      font-family: fontBold;
      font-size: 1.3rem;
      color: $theme-three;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $theme-four;
      }

      #inc-top-menu-active {
        position: absolute;
        bottom: 0;
        // left: 0;
        height: 8px;
        width: 90%;
        border-radius: 0.5rem;
        background-color: $theme-three;
        transition: left 0.3s ease-in-out;
      }
    }

    .inc-top-left {
      position: absolute;
      right: 4rem;
      font-size: 1.1rem;
      color: #202020;

      a {
        color: #202020;
        font-weight: 600;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  #inc-bottom-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .inc-bottom-indi-box {
      position: relative;
      height: 10rem;
      width: 22%;
      margin: 1rem;
      border-radius: 0.5rem;
      box-shadow: 3px 3px 15px 0 $shadow;
      cursor: pointer;

      &:hover {
        box-shadow: 3px 3px 15px 0 $shadow-two;
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  #inc-container {
    position: relative;
    height: auto;
    padding: 1rem 1rem 2rem 1rem;
    margin-top: 0rem;

    #inc-header-cover {
      display: block;
      position: absolute;
      top: -4.5rem; left: 0;
      height: 4.5rem;
      width: 100%;
      background-color: $theme-three;
      box-sizing: border-box;
    }

    #inc-top-box {
      height: auto;
      align-items: flex-start;
      .inc-top-left {
        right: unset;
        bottom: 0;
        left: 0.5rem;
      }
    }

    #inc-bottom-box {
      .inc-bottom-indi-box {
        height: fit-content;
        margin: 1rem 1rem 0 1rem;
        width: 100%;
        box-shadow: none;

        &:hover {box-shadow: none;}

        img {
          height: auto;
          width: 100%;
          box-shadow: 3px 3px 15px 0 $shadow;

          &:hover {
            box-shadow: 3px 3px 15px 0 $shadow-two;
          }
        }
      }
    }
  }
}
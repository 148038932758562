$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);
$theme-six: var(--theme-six);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);


#home-container {
  width: 100%;
  // margin-top: 5rem;
  box-sizing: border-box;

  #home-box-zero-six {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../../Images/Home-one/gOne.jpg");
    background-position: center;
    background-size: cover;
    z-index: -5;
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; 
      width: 100%;
      background-color: #00000080;
    }
  }
  
  #home-box-zero-seven {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../../Images/Waves/sThree.jpeg");
    background-position: center;
    background-size: cover;
    z-index: -5;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #49589a30;
    }
  }

  // slides
  #home-box-one {
    position: relative;
    height: 50rem;
    // height: calc(100vh - 10rem);
    // height: calc(100vh);
    // min-height: 30rem;
    width: 100%;
    padding: 0 10rem;
    padding-top: 0rem;
    overflow-y: hidden;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    // background-color: green;
    
    .home-one-img {
      // display: none;
      position: absolute;
      top: 0;
      height: 50rem;
      // height: calc(100vh - 10rem);
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      animation-duration: 0.6s;
      animation-fill-mode: both;
      
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #00000050;
      }
    }
    
    #home-one-one-show {
      left: 0;
      width: 0%;
      animation-name: one-show;
    }
    #home-one-one-hide {
      left: 0;
      width: 100%;
      animation-name: one-hide;
    }
    @keyframes one-show {
      100% {
        width: 100%;
      }
    }
    @keyframes one-hide {
      100% {
        width: 0;
      }
    }
    #home-one-two-show {
      right: 0;
      width: 0%;
      animation-name: two-show;
    }
    #home-one-two-hide {
      right: 0;
      width: 100%;
      animation-name: two-hide;
    }
    @keyframes two-show {
      100% {
        width: 100%;
      }
    }
    @keyframes two-hide {
      100% {
        width: 0;
      }
    }

    #home-box-one-top {
      height: auto;
      width: 100%;
      padding: 8rem 0 6rem 0;
      box-sizing: border-box;
      #home-box-one-top-box {
        position: relative;
        height: auto;
        padding-bottom: 10rem;
        box-sizing: border-box;
        overflow-y: hidden;
        .home-box-one-top-title {
          position: relative;
          top: 10rem;
          height: auto;
          opacity: 0;
          font-size: 5rem;
          line-height: 5rem;
          font-family: fontExBold;
          color: rgb(0, 0, 0);
          animation-name: one-top-title;
          animation-duration: 0.6s;
          animation-fill-mode: both;
          box-sizing: border-box;
        }
        .home-box-one-top-subtitle {
          position: relative;
          top: 13rem;
          opacity: 0;
          height: auto;
          margin-top: 1.5rem;
          font-size: 2.5rem;
          color: white;
          line-height: 2.5rem;
          animation-name: one-top-subtitle;
          animation-duration: 0.6s;
          animation-fill-mode: both;
          box-sizing: border-box;
        }
        @keyframes one-top-title {
          100% {
            top: 0;
            opacity: 1;
          }
        }
        @keyframes one-top-subtitle {
          100% {
            top: 0rem;
            opacity: 1;
          }
        }
      }
    }
  }

  // three boxes
  #home-box-two {
    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    // column-gap: 0.05rem;
    column-gap: 0rem;
    height: auto;
    width: 100%;
    margin-top: -2rem;
    padding: 0 4rem;
    background-color: transparent;
    box-sizing: border-box;

    .home-box-two-box {
      height: 16rem;
      width: 30%;
      // margin-top: -17.5rem;
      margin-top: -20rem;
      padding: 2rem;
      box-sizing: border-box;
      // border-radius: 0.5rem;
      background-color: #fff;
      color: $bg;
      // box-shadow: -3px 0px 5px 0 #30303050;      
      .home-box-two-box-title {
        font-size: 1.75rem;
      }
      .home-box-two-box-subtitle {
        padding: 1rem 0 1.5rem 0;
        font-size: 1rem;
        letter-spacing: 0.3px;
        height: 5rem;
      }
      .home-box-two-box-btn {
        display: inline-flex;
        padding: 0.8rem 1.75rem;
        border: 1px solid #fff;
        // border-radius: 0.5rem;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        // box-shadow: 3px 3px 10px 0 #ccc;
        &:hover {
          cursor: pointer;
          // color: black;
          background-color: #fff;
          // box-shadow: 3px 3px 10px 0 #bbb;
          a {
            color: #000;
          }
        }
        a {
          color: #fff;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
        }
      }

      &:nth-child(1) { background-color: $theme-two; }
      &:nth-child(2) { background-color: $theme-three; }
      &:nth-child(3) { background-color: $theme-four; }

    }
  }

  // facilities
  #home-box-three {
    margin-top: 100px;
    height: auto;
    width: 100%;
    padding-top: 0rem;
    background-color: var(--bg);
    overflow-y: hidden;
    #home-box-three-top {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      height: 15rem;
      width: 100%;
      padding-top: 0rem;
      box-sizing: border-box;
      #home-box-three-top-title {
        // font-size: 3rem;
        font-family: fontBold;
        text-align: center;
        color: $theme-three;
      }
      #home-box-three-top-subtitle {
        position: relative;
        width: 35%;
        margin-top: 1rem;
        // font-size: 1.1rem;
        letter-spacing: 0.3px;
        word-spacing: 1px;
        text-align: center;
        color: grey;
        font-family: fontReg;
      }
    }
    #home-box-three-bottom {
      height: 100%;
      width: 100%;
      margin-top: 0rem;
      box-sizing: border-box;
      #home-box-three-bottom-top {
        display: flex;
        justify-content: space-evenly;
        // flex-flow: row wrap;
        column-gap: 10px;
        height: 25rem;
        width: 100%;
        overflow-y: hidden;
        // background-color: red;
        #home-box-three-bottom-top-left {
          height: 100%;
          width: 40%;
          #home-box-three-bottom-top-left-top {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            width: 25rem;
            margin: 0 auto;
            padding: 1rem;
            font-size: 1.05rem;
            letter-spacing: 0.3px;
            word-spacing: 1px;
            color: grey;
            text-align: center;
            box-sizing: border-box;
            overflow-y: hidden;
          }
        }
      }
      #home-box-three-bottom-bottom {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        // flex-flow: row wrap;
        column-gap: 10px;
        height: 25rem;
        width: 100%;
        margin-top: 10px;
        overflow-y: hidden;
        #home-box-three-bottom-bottom-mid {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 40%;
          padding: 0rem;
          text-align: center;
          font-size: 1.05rem;
          letter-spacing: 0.3px;
          word-spacing: 1px;
          color: #fff;
          box-sizing: border-box;
          overflow-y: hidden;
          background-image: url("../../../Images/Interior/307.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            // background-color: #00000090;
          }
        }
      }
      .home-box-three-bottom-img {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &::before {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          background-color: #00000010;
        }
      }
      #home-three-one {
        height: 15rem;
        width: 100%;
        background-image: url("../../../Images/Interior/304.jpg");
      }
      #home-three-two {
        height: 100%;
        width: 30%;
        background-image: url("../../../Images/Interior/305.jpg");
      }
      #home-three-three {
        height: 100%;
        width: 30%;
        background-image: url("../../../Images/Interior/303.jpg");
      }
      #home-three-four {
        height: 100%;
        width: 30%;
        background-image: url("../../../Images/Interior/302.jpg");
      }
      #home-three-five {
        height: 100%;
        width: 30%;
        background-image: url("../../../Images/Interior/309.jpg");
      }
      #home-three-six {
        height: 10rem;
        width: 100%;
        background-image: url("../../../Images/Interior/308.jpg");
      }
    }
    .home-box-three-text-anime-one {
      position: absolute;
      top: 120%;
      width: 20rem;
      animation-name: three-one;
      animation-duration: 2s;
      animation-fill-mode: both;
      opacity: 0;
    }
    .home-box-three-text-anime-two {
      position: absolute;
      top: 120%;
      width: 20rem;
      opacity: 0;
      animation-name: three-two;
      animation-duration: 1.5s;
      animation-fill-mode: both;
    }
    @keyframes three-one {
      100% {
        opacity: 1;
        top: 1.5rem;
      }
    }
    @keyframes three-two {
      100% {
        opacity: 1;
        top: 10rem;
      }
    }
    #home-three-btn {
      width: fit-content;
      margin: 3rem auto;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      color: black;
      background-color: #fff;
      box-shadow: 3px 3px 10px 0 #ccc;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      z-index: 6;
      &:hover {
        color: #fff;
        background-color: var(--theme-three);
      }
    }
  }

  // departments
  #home-box-four {
    position: relative;
    height: auto;
    width: 100%;
    padding: 3rem 0 5rem 0;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    #home-box-four-top {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      height: 10rem;
      width: 100%;
      padding-top: 0rem;
      box-sizing: border-box;
      .home-box-four-top-subtitle-en {font-size: 1.1rem;}
      .home-box-four-top-subtitle-ar {font-size: 1.1rem;}
      #home-box-four-top-title {
        width: auto;
        // font-size: 3rem;
        font-family: fontBold;
        text-align: center;
        color: var(--theme-three);
      }
      #home-box-four-top-subtitle {
        width: 35%;
        margin-top: 1rem;
        letter-spacing: 0.3px;
        word-spacing: 1px;
        text-align: center;
        color: #202020;
        font-family: fontReg;
      }
    }
    #home-box-four-bottom {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      height: auto;
      width: 100%;
      margin-top: 1rem;
      .home-box-four-bottom-box {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        height: 18rem;
        width: 100%;
        &:nth-child(1), &:nth-child(2) {
          margin-bottom: 2rem;
        }
        .home-box-four-bottom-box-indi {
          position: relative;
          height: 100%;
          width: 18rem;
          margin: 1rem;
          border-radius: 1rem;
          cursor: pointer;
          background-color: #fbfbfb;
          box-shadow: 3px 3px 10px 0 #ddd;
          &:hover {
            box-shadow: 3px 3px 10px 0 #ccc;
            background-size: 300%;
          }
          .home-box-four-bottom-box-indi-icon {
            display: flex;
            align-items: end;
            justify-content: center;
            height: 7rem;
            width: 100%;
            box-sizing: border-box;
            img {
              height: 4.5rem;
              box-sizing: border-box;
            }
          }
          .home-box-four-bottom-box-indi-title {
            height: 2.5rem;
            width: 100%;
            padding: 1rem 0 0 0;
            box-sizing: border-box;
            font-size: 1.3rem;
            font-family: fontExBold;
            text-align: center;
          }
          .hbfbbis-en {
            font-size: 1.05rem;
          }
          .hbfbbis-ar {
            font-size: 1.2rem;
          }
          .home-box-four-bottom-box-indi-subtitle {
            padding: 1rem 2rem;
            height: 8rem;
            width: 100%;
            text-align: center;
            letter-spacing: 0.3px;
            word-spacing: 1px;
            color: #404040;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  // mission vision value
  #home-box-five {
    background-image: url('../../../Assets//vision-mission/vission-background.png');
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
    height: 70vh;
    margin-top: -4px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    // backdrop-filter: blur(1px);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #00000040;
      z-index: -1;
    }
    #home-box-five-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 2rem;
      height: 20rem;
      width: 85%;
      box-sizing: border-box;
      .home-five-indi-box {
        background-color: white;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        height: 100%;
        width: 30%;
        border-radius: 1rem;
        box-sizing: border-box;
        box-shadow: #6969b3 0px 7px 29px 0px;
        // border: 1px solid #00000040;
        // box-shadow: 0 0 10px 3px #cfcfcf54;
        backdrop-filter: blur(5px);
        .home-five-indi-icon {
          position: relative;
          height: 5rem;
          width: 5rem;
          padding: 2rem;
          margin-top: 16px;
          margin-bottom: -33px;
        }
        .home-five-indi-title-en {font-size: 1.5rem;}
        .home-five-indi-title-ar {font-size: 2rem;}
        .home-five-indi-title {
          height: 2rem;
          width: 100%;
          color: black;
          text-align: center;
          font-family: fontReg;
          font-weight: 600;
          margin-top: -20px;
        }
        .home-five-indi-subtitle-en {font-size: 1.1rem;}
        .home-five-indi-subtitle-ar {font-size: 1.25rem;}
        .home-five-indi-subtitle {
          text-align: center;
          padding: 0 1rem;
          color: black;
          margin-top: -31px;
        }
        .home-five-indi-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 2rem;
          padding: 0.5rem 1rem;
          box-shadow: 3px 3px 15px 0 #8293dc30;
          border-radius: 0.5rem;
          cursor: pointer;
          background-color: #fff;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          &:hover {
            background-color: var(--theme-two);
            color: white;
          }
        }
      }
    }
  }

  // apps
  #home-box-six {
    height: 70vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 4rem 6rem;
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    #home-six-left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 60%;
      color: #fff;
      font-family: fontExBold;
      padding: 0 2rem;
      box-sizing: border-box;
      #home-six-title {
        margin-top: -10rem;
        font-size: 4rem;
      }
      #home-six-subtitle {
        margin-top: 3rem;
        font-size: 2rem;
      }
    }
    .home-six-logos {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      width: auto;
      margin-top: 2rem;
      .home-six-apps {
        height: 4rem;
        width: 12rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        &:first-child {
          background-image: url("../../../Images/app-store.png");
        }
        &:last-child {
          margin-left: 2rem;
          background-image: url("../../../Images/google-play.png");
        }
      }
    }
    #home-six-right {
      position: relative;
      height: 100%;
      width: 40%;
      background-image: url("../../../Images/a.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      #home-six-right-two {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background-image: url("../../../Images/app-mockup-2.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  // insurence
  #home-box-seven {
    position: relative;
    height: 60vh;
    margin-top: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: #9090c2 0px 7px 29px 0px;
    // background-color: var(--theme-three);
    background-image: url("../../../Images/map-pattern.png");
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    #home-box-seven-title {
      margin: 0 auto;
      padding: 4.5rem 0 0 0;
      width: fit-content;
      // font-size: 2.5rem;
      font-family: fontBold;
      color: var(--theme-three);
      text-align: center;
    }
    #home-box-seven-in-box {
      position: relative;
      height: 20rem;
      width: 85%;
      margin: 0rem auto 0 auto;
      #home-box-seven-in-anime {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: -150%;
        opacity: 1;
        height: 100%;
        width: 100%;
        animation-name: seven-anime;
        animation-duration: 2s;
        animation-fill-mode: both;
        .home-seven-in-indi {
          position: relative;
          height: 13rem;
          width: 17rem;
          background-color: white;
          border-radius: 0.5rem;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          &:nth-child(1) {
            background-image: url("../../../Images/Insurance/malath.jpg");
          }
          &:nth-child(2) {
            background-image: url("../../../Images/Insurance/gulfunion.png");
          }
          &:nth-child(3) {
            background-image: url("../../../Images/Insurance/tawuniya.png");
          }
          &:nth-child(4) {
            background-image: url("../../../Images/Insurance/globemed.png");
          }
          &:nth-child(5) {
            background-image: url("../../../Images/Insurance/rajhi.jpg");
            background-color: #f8f9f9;
          }
        }
      }
      @keyframes seven-anime {
        100% {
          left: 0;
          opacity: 1;
        }
      }
    }
    #home-box-seven-btn {
      width: fit-content;
      margin: 0 9rem 0 auto;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      color: black;
      background-color: #fff;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      z-index: 6;
      &:hover {
        color: #fff;
        background-color: var(--theme-three);
        box-shadow: 3px 3px 10px 0 $theme-four;
      }
    }
  }

  // doctors
  #home-box-eight {
    height: 85vh;
    width: 100%;
    background-color: var(--bg);
    box-sizing: border-box;
    #home-box-eight-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30%;
      width: 85%;
      margin: 0 auto;
      box-sizing: border-box;
      #home-box-eight-top-one {
        box-sizing: border-box;
        #home-box-eight-top-one-title {
          // font-size: 3rem;
          font-family: fontBold;
          color: var(--theme-three);
        }
        #home-box-eight-top-one-subtitle {
          // font-size: 1.2rem;
          color: grey;
          margin-top: 1rem;
          letter-spacing: 0.3px;
          word-spacing: 1px;
          padding-right: 5rem;
          box-sizing: border-box;
        }
      }
      #home-box-eight-top-two {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: 50%;
        width: 30%;
        box-sizing: border-box;
        #home-box-eight-top-two-btn {
          padding: 0.75rem 1.5rem;
          box-shadow: 3px 3px 10px 0 var(--shadow);
          border-radius: 0.5rem;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          &:hover {
            background-color: $theme-three;
            color: white;
          }
        }
      }
    }
    #home-box-eight-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 65%;
      width: 85%;
      margin: 0 auto;
      .home-box-eight-bottom-indi {
        position: relative;
        height: 23rem;
        width: 17rem;
        margin: 0 1rem;
        border-radius: 0.5rem;
        box-shadow: 3px 7px 10px 0 var(--shadow);

        &:first-child {margin-left: 0;}
        &:last-child {margin-right: 0;}

        &:nth-child(2) .home-box-eight-bottom-indi-top {
          background-image: url("../../../Images/Dr/3.JPG");
        }
        &:nth-child(3) .home-box-eight-bottom-indi-top {
          background-image: url("../../../Images/Dr/5.jpg");
        }
        &:nth-child(4) .home-box-eight-bottom-indi-top {
          background-image: url("../../../Images/Dr/6.jpg");
        }
        &:nth-child(5) .home-box-eight-bottom-indi-top {
          background-image: url("../../../Images/Dr/10.JPG");
        }
        &:hover {
          cursor: pointer;
          .home-box-eight-bottom-indi-bottom {
            top: 0;
            height: 100%;
            padding: 1rem;
            color: white;
            background-image: linear-gradient(to bottom, var(--theme-three), transparent);
          }
        }
        .home-box-eight-bottom-indi-top {
          position: relative;
          height: 100%;
          width: 100%;
          border-radius: 0.5rem;
          background-image: url("../../../Images/Dr/2.jpg");
          background-position: center;
          background-size: cover;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0.5rem;
            height: 100%;
            width: 100%;
            background-color: #00000005;
          }
        }
        .home-box-eight-bottom-indi-bottom {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          position: absolute;
          top: 60%;
          height: 40%;
          width: 100%;
          padding: 3.5rem 1rem 1rem 1rem;
          border-radius: 0.5rem;
          color: white;
          background-image: linear-gradient(to top, var(--theme-three), transparent);
          box-sizing: border-box;
          transition: top 0.5s ease-in-out, backround-image 0.5s ease-in-out, height 0.5s ease-in-out, color 0.5s ease-in-out;
          .home-box-eight-bottom-indi-bottom-title {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  // about us
  #home-box-nine {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    // background-image: url("../../../Images/Waves/six.svg");
    background-color: var(--bg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    #home-box-nine-mid {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: auto;
      width: 85%;
      margin: 4rem 0 4rem 0;
      box-sizing: border-box;
      #home-box-nine-mid-left {
        position: relative;
        transform: translateX(120%);
        height: auto;
        width: 50%;
        transition: transform 2s ease-in-out;
        animation-name: nine-left;
        animation-duration: 2s;
        animation-fill-mode: both;
        box-sizing: border-box;
        #home-box-nine-mid-title {
          height: 3.5rem;
          margin-top: 2rem;
          // font-size: 3rem;
          font-family: fontBold;
          color: $theme-three;
        }
        #home-box-nine-mid-subtitle {
          height: fit-content;
          padding: 3rem 0;
          line-height: 1.7rem;
          letter-spacing: 0.5px;
          word-spacing: 3px;
        }
        // .home-box-nine-mid-subtitle-en {font-size: 1.2rem;}
        // .home-box-nine-mid-subtitle-ar {font-size: 1.5rem;}
        #home-box-nine-mid-btn {
          width: fit-content;
          padding: 0.75rem 1.5rem;
          box-shadow: 3px 3px 10px 0 var(--shadow-two);
          border-radius: 0.5rem;
          cursor: pointer;
          background-color: var(--bg);
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          &:hover {
            background-color: $theme-three;
            color: white;
          }
        }
      }
      #home-box-nine-mid-right {
        position: relative;
        height: 100%;
        width: 50%;
        padding: 2.5rem 0rem 1rem 0;
        transform: translateX(-120%);
        transition: transform 2s ease-in-out;
        animation-name: nine-right;
        animation-duration: 2s;
        animation-fill-mode: both;
        box-sizing: border-box;
        .home-box-nine-mid-right-img {
          height: 35rem;
          width: 37rem;
          // height: 30rem;
          // width: 26rem;
          border-radius: 1rem;
          // background-image: url('../../../Images/aboutSix.jpeg');
          background-image: url('../../../Images/Interior/303.jpg');
          background-size: cover;
          background-position: center;
        }
      }
      // #home-box-nine-mid-right {
      //   position: relative;
      //   height: 100%;
      //   width: 50%;
      //   padding: 2.2rem 0rem 1rem 0;
      //   transform: translateX(-120%);
      //   transition: transform 2s ease-in-out;
      //   animation-name: nine-right;
      //   animation-duration: 2s;
      //   animation-fill-mode: both;
      //   box-sizing: border-box;

      //   .aboutus-image-collash-box {
      //     position: relative;
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     flex-wrap: wrap;
      //     height: 35rem;
      //     width: 45rem;

      //     .aboutus-collash-images {
      //       position: relative;
      //       height: 48%;
      //       width: 49%;
      //       background-image: url('../../../Images/Interior/303.jpg');
      //       background-size: cover;
      //       background-position: center;
            
      //       &:nth-child(1) {background-image: url('../../../Images/Interior/301.jpg');}
      //       &:nth-child(2) {background-image: url('../../../Images/Interior/306.jpg')}
      //       &:nth-child(3) {background-image: url('../../../Images/Interior/304.jpg')}
      //       &:nth-child(4) {background-image: url('../../../Images/Interior/305.jpg')}
      //       // &:nth-child(1) {border-radius: 2rem 2rem 0 2rem; background-image: url('../../../Images/Interior/301.jpg');}
      //       // &:nth-child(2) {border-radius: 2rem 2rem 2rem 0; background-image: url('../../../Images/Interior/115.jpg')}
      //       // &:nth-child(3) {border-radius: 2rem 0 2rem 2rem; background-image: url('../../../Images/Interior/304.jpg')}
      //       // &:nth-child(4) {border-radius: 0 2rem 2rem 2rem; background-image: url('../../../Images/Interior/306.jpg')}
      //     }
      //   }
      // }
      @keyframes nine-left {
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      @keyframes nine-right {
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
    #home-box-nine-bottom {
      width: 100%;
      padding-bottom: 4rem;
      #home-box-nine-bottom-title {
        height: 3.5rem;
        width: 80%;
        margin: auto;
        padding-bottom: 1rem;
        font-size: 2.5rem;
        font-family: fontBold;
        color: var(--theme-two);
      }
      #sis-center {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        width: 80%;
        margin: 0 auto;
        box-sizing: border-box;
        .sis-cards {
          display: flex;
          align-items: center;
          height: 10rem;
          width: 45%;
          padding: 1rem;
          background-color: var(--bg);
          border-radius: 0.75rem;
          box-shadow: 2px 2px 10px 0 #ddd;
          box-sizing: border-box;
          .sis-cards-left {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 8rem;
            .sis-cards-left-logo {
              height: 6rem;
              width: 6rem;
              background-image: url("../../../Images/Header/logo1B.svg");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          .sis-cards-right {
            height: 6rem;
            width: auto;
            margin-left: 1rem;
            .sis-cards-right-title {
              font-size: 1.5rem;
              font-family: fontBold;
              color: var(--theme-two);
            }
            .sis-cards-right-subtitle {
              margin-top: 0.5rem;
              font-size: 1rem;
            }
            .sis-cards-right-link {
              margin-top: 0.5rem;
              a {
                text-decoration: none;
                color: #000;
                font-weight: bolder;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          &:last-child {
            margin-left: 4rem;
          }
        }
      }
    }
  }

  // management
  #home-box-ten {
    position: relative;
    height: auto;
    // background-image: url('../../../Images/Management/140622.svg'), linear-gradient(-30deg, #8293DC, #6b7dce);
    background-color: #eee;
    background-size: cover;
    padding: 4rem 0;
    background-position: center;
    #home-box-ten-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30%;
      width: 85%;
      margin: auto;
      box-sizing: border-box;
      #home-box-ten-top-one {
        box-sizing: border-box;
        #home-box-ten-top-one-title {
          font-size: 3rem;
          font-family: fontBold;
          margin-top: 34px;
          /* color: #fff; */
          color: var(--theme-three);
        }
      }
      #home-box-ten-top-two {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 4rem;
        width: 30%;
        box-sizing: border-box;
        #home-box-ten-top-two-btn {
          padding: 0.75rem 1.5rem;
          box-shadow: 3px 3px 10px 0 #ccc;
          border-radius: 0.5rem;
          background-color: #fff;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          &:hover {
            background-color: var(--theme-two);
            color: white;
          }
        }
      }
    }
    #home-box-ten-bottom {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      height: auto;
      width: 85%;
      margin: 0 auto;
      margin-top: 2rem;
      padding: 2rem 0;
      .home-box-ten-indi {
        position: relative;
        height: 23rem;
        width: 16rem;
        margin: 0 0rem;
        border-radius: 1rem;
        box-sizing: border-box;
        background-size: cover;
        background-position: center;
        // box-shadow: 3px 3px 15px 0 $theme-three;
        box-shadow: 3px 3px 15px 0 #ccc;
        &::after {
          position: absolute;
          bottom: 40%;
          left: 0;
          height: 0;
          width: 100%;
          color: white;
          opacity: 0;
          padding: 1rem;
          border-radius: 0.5rem;
          background-image: linear-gradient(to bottom, black, transparent);
          box-sizing: border-box;
          transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out, bottom 0.5s ease-in-out;
        }
        .home-box-ten-indi-bottom {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          flex-direction: column;
          position: absolute;
          bottom: 0;
          height: 60%;
          width: 100%;
          border-radius: 1rem;
          background-image: linear-gradient(to top, black, transparent);
          padding: 1rem;
          box-sizing: border-box;
          color: white;
          .home-box-ten-indi-bottom-title {
            font-size: 1.4rem;
            font-weight: 900;
          }
          .home-box-ten-indi-bottom-subtitle {
            font-size: 1.1rem;
          }
        }

        &:nth-child(1) {
          background-image: url("../../../Images/Management/six.jpeg");
          background-position: -5px -5px;
          margin-bottom: 2rem;
          &::after {
            content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
          }
        }
        &:nth-child(2) {
          background-image: url("../../../Images/Management/seven.jpeg");
          &::after {
            content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
          }
        }
        &:nth-child(3) {
          background-image: url("../../../Images/Management/one.jpg");
          margin-bottom: 2rem;
          &::after {
            content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
          }
        }
        &:nth-child(4) {
          background-image: url("../../../Images/Management/four.jpg");
          &::after {
            content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
          }
        }
      }
      #ten-bottom-one {
        padding: 2rem 0;
        .home-box-ten-indi {
          &:nth-child(1) {
            background-image: url("../../../Images/Management/six.jpeg");
            background-position: -5px -5px;
            margin-bottom: 2rem;
            &::after {
              content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
            }
          }
          &:nth-child(2) {
            background-image: url("../../../Images/Management/seven.jpeg");
            &::after {
              content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
            }
          }
        }
      }
    }
    #ten-bottom-two {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 22rem;
      width: 17rem;
      font-size: 5.5rem;
      line-height: 5rem;
      background-image: url("../../../Images/Header/logo1B.svg");
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      text-align: center;
      letter-spacing: 0.5rem;
      font-family: asianFont;
      color: $theme-three;
      margin: 0;
      padding-left: 10px;
    }
    #ten-bottom-three {
      padding: 2rem 0;
      .home-box-ten-indi {
        &:nth-child(1) {
          background-image: url("../../../Images/Management/one.jpg");
          margin-bottom: 2rem;
          &::after {
            content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
          }
        }
        &:nth-child(2) {
          background-image: url("../../../Images/Management/four.jpg");
          &::after {
            content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
          }
        }
      }
    }
  }

} 
 

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
  
  
@media screen and (max-width: 1024px) {

  #home-container {
    width: 100%;
    margin-top: -4.5rem;
    z-index: 12;
    box-sizing: border-box;
    overflow-x: hidden;

    #home-box-one {
      position: relative;
      height: 17rem;
      width: 100%;
      padding: 0 1rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: 5rem;
      padding-bottom: 0rem;
      box-sizing: border-box;
      .home-one-img {
        height: 17rem;
        background-size: cover;
        background-position: top;
      }
      #home-box-one-top {
        height: 17rem;
        width: 100%;
        padding: 1rem 0 2rem 0;
        box-sizing: content-box;
        #home-box-one-top-box {
          position: relative;
          height: 10.5rem;
          box-sizing: border-box;
          overflow-y: hidden;
          .home-box-one-top-title {
            position: relative;
            height: auto;
            opacity: 1;
            font-size: 2rem;
            line-height: 2rem;
            font-family: fontExBold;
            color: white;
            animation-name: one-top-title;
            animation-duration: 0.6s;
            animation-fill-mode: both;
            box-sizing: border-box;
          }
          .home-box-one-top-subtitle {
            position: relative;
            height: auto;
            opacity: 1;
            padding-top: 0rem;
            margin-top: 0.75rem;
            font-size: 1rem;
            color: white;
            line-height: 1.3rem;
            animation-name: one-top-subtitle;
            animation-duration: 0.6s;
            animation-fill-mode: both;
            box-sizing: border-box;
          }
          @keyframes one-top-title {
            100% {
              top: 0;
              opacity: 1;
            }
          }
          @keyframes one-top-subtitle {
            100% {
              top: 0rem;
              opacity: 1;
            }
          }
        }
      }
    }
  
    #home-box-two {
      position: relative;
      display: block;
      height: auto;
      width: 100%;
      margin-top: 0rem;
      padding: 1rem 1rem 3rem 1rem;
      box-sizing: border-box;
      .home-box-two-box {
        height: auto;
        width: 100%;
        padding: 1rem;
        margin: 0 0;
        // border-radius: 0.5rem;
        box-sizing: border-box;
        color: #fff;
        .home-box-two-box-title {
          font-size: 1.5rem;
        }
        .home-box-two-box-subtitle {
          padding: 0.75rem 0 0.5rem 0;
          font-size: 0.95rem;
        }
        .home-box-two-box-btn {
          // border-radius: 0.5rem;
          display: inline-flex;
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          a {
            color: #fff;
          }
          .home-box-two-box-btn:hover {
            cursor: pointer;
            color: black;
            background-color: white;
          }
        }
        &:first-child {
          // border-radius: 1rem;
          background-color: $theme-two;
          // box-shadow: -3px 0px 5px 0 #30303050;
          // .home-box-two-box-btn {
            // box-shadow: 3px 3px 10px 0 #ccc;
          // }
        }
        &:nth-child(2) {
          // border-radius: 1rem;
          background-color: $theme-three;
          // box-shadow: 0px 0px 5px 0 #25252550;
          // .home-box-two-box-btn {
            // box-shadow: 3px 3px 10px 0 #ccc;
          // }
        }
        &:last-child {
          // border-radius: 1rem;
          background-color: $theme-four;
          // box-shadow: 3px 0px 5px 0 #20202050;
          // .home-box-two-box-btn {
            // box-shadow: 3px 3px 10px 0 #ccc;
          // }
        }
      }
    }
    
    #home-box-three {
      height: auto;
      width: 100%;
      padding-top: 0rem;
      background-color: white;
      overflow-x: hidden;
      #home-box-three-top {
        #home-box-three-top-title {
          font-size: 2.5rem;
          font-family: fontExBold;
        }
        #home-box-three-top-subtitle {
          width: 90%;
          font-size: 1.1rem;
        }
      }
      #home-box-three-bottom {
        height: 100%;
        width: 100%;
        margin-top: 0rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
        overflow-x: hidden;
        #home-box-three-bottom-top {
          display: block;
          height: auto;
          width: auto;
          padding: 0;
          row-gap: 5px;
          #home-box-three-bottom-top-left {
            height: auto;
            width: 100%;
            #home-box-three-bottom-top-left-top {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              height: 13rem;
              width: 100%;
              margin: 5px auto;
              padding: 1rem 1rem;
              font-size: 1.1rem;
              color: #fff;
              text-align: center;
              box-sizing: border-box;
              background-image: url("../../../Images/Interior/305.jpg");
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              &::before {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                background-color: #00000090;
              }
            }
          }
        }
        #home-box-three-bottom-bottom {
          display: block;
          height: auto;
          width: 100%;
          margin-top: unset;
          #home-box-three-bottom-bottom-mid {
            height: 20rem;
            width: 100%;
            padding: 5rem 1rem;
            text-align: center;
            font-size: 1.1rem;
            color: #fff;
            box-sizing: border-box;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .home-box-three-bottom-img {
          position: relative;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin: 5px 0;
          &:first-child {
            margin-top: 0;
          }
        }
        #home-three-one {
          height: 15rem;
          width: 100%;
        }
        #home-three-two {
          height: unset;
          width: unset;
        }
        #home-three-three {
          height: 15rem;
          width: 100%;
        }
        #home-three-four {
          height: 15rem;
          width: 100%;
        }
        #home-three-five {
          height: 15rem;
          width: 100%;
        }
        #home-three-six {
          height: 15rem;
          width: 100%;
        }
        .home-box-three-text-anime-one {
          height: auto;
        }
        @keyframes three-one {
          100% {
            opacity: 1;
            top: 3rem;
          }
        }
        @keyframes three-two {
          100% {
            opacity: 1;
            top: 6rem;
          }
        }    
      }
    }
  
    #home-box-four {
      height: auto;
      width: 100%;
      padding: 0rem 1rem 2rem 1rem;
      color: var(--border-one);
      background-color: #eee;
      background-position: 20%;
      box-sizing: border-box;
      #home-box-four-top {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        height: 10rem;
        width: 100%;
        padding-top: 5rem;
        box-sizing: border-box;
        #home-box-four-top-title {
          width: auto;
          font-size: 2.25rem;
          font-family: fontBold;
          color: $theme-three;
          text-align: center;
        }
        #home-box-four-top-subtitle {
          width: 100%;
          margin-top: 1rem;
          padding: 0 1rem;
          // font-size: 1rem;
          text-align: center;
          color: #252525;
          box-sizing: border-box;
        }
      }
      #home-box-four-bottom {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        height: auto;
        width: auto;
        margin-top: 3rem;
        .home-box-four-bottom-box {
          display: block;
          height: auto;
          width: 47.5%;
          box-sizing: border-box;
          &:nth-child(1) .home-box-four-bottom-box-indi, &:nth-child(2) .home-box-four-bottom-box-indi {
            height: 100%;
            width: 100%;
            margin: 1rem 0;
            padding: 1rem 0 0 0;
            box-sizing: border-box;
            &:hover {
              background-color: #ddd;
              box-shadow: 3px 3px 10px 0 #ccc;
            }
            .home-box-four-bottom-box-indi-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 6rem;
              width: 6rem;
              margin: 0 auto;
              border-radius: 50%;
              /* background-color: var(--theme-two); */
              background-color: #eee;
              box-sizing: border-box;
              img {
                height: 3.5rem;
              }
            }
            .home-box-four-bottom-box-indi-title {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 4rem;
              width: 100%;
              padding: 0;
              box-sizing: border-box;
              font-size: 1.1rem;
              border-radius: 0.5rem;
              color: #000;
              text-align: center;
            }
            .home-box-four-bottom-box-indi-subtitle {
              display: none;
              padding: 1rem 2rem;
              height: 8rem;
              width: 100%;
              text-align: center;
              box-sizing: border-box;
              color: white;
            }
          }
        }
      }
    }
  
    #home-box-five {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;
      height: auto;
      width: 100%;
      padding: 1rem 0 3rem 0;
      box-sizing: border-box;
      backdrop-filter: none;
      #home-box-five-container {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 90%;
        box-sizing: border-box;
        .home-five-indi-box {
          margin: 0.5rem 0 1.5rem 0;
          height: 20rem;
          width: 100%;
          border-radius: 0.5rem;
          box-sizing: border-box;
          // box-shadow: 0 0 10px 3px #dfdfdf57;
          backdrop-filter: blur(5px);
          .home-five-indi-icon {
            padding: 1rem;
          }
          &:last-child {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    
    #home-box-six {
      height: 100vh;
      flex-direction: column;
      padding: 0rem 1.5rem;
      #home-six-left {
        height: 100%;
        width: 100%;
        padding: 0 0rem;
        #home-six-title {
          margin-top: 0;
          font-size: 3rem;
        }
        #home-six-subtitle {
          margin-top: 1rem;
          font-size: 1.75rem;
        }
      }
      .home-six-logos {
        justify-content: center;
        margin: 3rem auto;
        .home-six-apps {
          height: 4rem;
          width: 10rem;
        }
      }
      #home-six-right {
        width: 100%;
        background-position: center;
        background-image: url("../../../Images/app-mockup-3.svg");
        #home-six-right-two {
          display: none;
        }
      }
    }
  
    #home-box-seven {
      position: relative;
      height: 105vh;
      width: 100%;
      box-sizing: border-box;
      #home-box-seven-title {
        margin: 27 auto;
        padding: 4.5rem 0 0 0;
        width: fit-content;
        font-size: 2rem;
        font-family: fontBold;
        color: white;
        text-align: center;
      }
      #home-box-seven-in-box {
        position: relative;
        height: auto;
        width: 90%;
        margin: 0 auto;
        #home-box-seven-in-anime {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          top: 4rem;
          left: -150%;
          opacity: 1;
          height: auto;
          width: 100%;
          animation-name: seven-anime;
          animation-duration: 2s;
          animation-fill-mode: both;
          .home-seven-in-indi {
            position: relative;
            height: 7rem;
            width: 90%;
            margin-bottom: 1rem;
            border-radius: 0.5rem;
            background-size: contain;
          }
        }
        @keyframes seven-anime {
          100% {
            left: 0;
            opacity: 1;
          }
        }
      }
      #home-box-seven-btn {
        position: absolute;
        bottom: 3rem;
        left: -50%;
        right: -50%;
        width: fit-content;
        margin: 0 auto;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        color: white;
        background-color: var(--theme-two);
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        &:hover {
          color: black;
          background-color: white;
        }
      }
    }

    @media screen and (max-height: 700px) {
      #home-box-seven {
        height: 140vh;
      }
    }
  
    #home-box-eight {
      height: auto;
      width: 100%;
      background-color: var(--bg);
      box-sizing: border-box;
      #home-box-eight-top {
        display: block;
        height: auto;
        width: 90%;
        margin: 0 auto;
        padding: 4rem 0 3rem 0;
        box-sizing: border-box;
        #home-box-eight-top-one {
          box-sizing: border-box;
          #home-box-eight-top-one-title {
            text-align: center;
            font-size: 2.5rem;
            font-family: fontBold;
            color: var(--theme-three);
          }
          #home-box-eight-top-one-subtitle {
            text-align: center;
            padding-right: 0;
          }
        }
      }
      #home-box-eight-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 65%;
        width: 100%;
        padding-bottom: 0rem;
        margin: 0 auto;
        .home-box-eight-bottom-indi {
          position: relative;
          height: 23rem;
          width: 18rem;
          margin: 0 0 1.5rem 0;
          border-radius: 0.75rem;
          box-shadow: 2px 2px 15px 0 #ddd;
          &:hover {
            cursor: pointer;
            .home-box-eight-bottom-indi-bottom {
              top: 0;
              height: 100%;
              color: white;
              background-image: linear-gradient(to bottom, var(--theme-three), transparent);
            }
          }
          .home-box-eight-bottom-indi-top {
            position: relative;
            height: 100%;
            width: 100%;
            border-radius: 0.75rem;
            background-position: center;
            background-size: cover;
          }
          .home-box-eight-bottom-indi-bottom {
            justify-content: flex-end;
            position: absolute;
            top: 60%;
            bottom: 1rem;
            height: 40%;
            width: 100%;
            padding: 0rem 1rem 1rem 1rem;
            border-radius: 0.75rem;
            background-image: linear-gradient(to top, var(--theme-three), transparent);
            box-sizing: border-box;
            transition: top 0.5s ease-in-out, backround-image 0.5s ease-in-out, height 0.5s ease-in-out, color 0.5s ease-in-out;
            .home-box-eight-bottom-indi-bottom-title {
              font-size: 1.6rem;
              font-weight: 600;
            }
            .home-box-eight-bottom-indi-bottom-subtitle {
              font-size: 1.1rem;
            }
          }
          &:nth-child(2) .home-box-eight-bottom-indi-bottom, &:nth-child(3) .home-box-eight-bottom-indi-bottom, &:nth-child(4) .home-box-eight-bottom-indi-bottom {
            padding: 5rem 1rem 1rem 1rem;
          }
          &:nth-child(2):hover .home-box-eight-bottom-indi-bottom, &:nth-child(3):hover .home-box-eight-bottom-indi-bottom, &:nth-child(4):hover .home-box-eight-bottom-indi-bottom {
            padding-top: 1rem;
          }
        }
      }
      #home-box-eight-phone-btn-box {
        width: 100%;
        padding: 1.5rem 0 4rem 0;
        #home-box-eight-phone-btn {
          width: fit-content;
          padding: 1rem 1.75rem;
          margin: 0 auto;
          box-shadow: 3px 3px 10px 0 var(--shadow);
          border-radius: 0.5rem;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          &:hover {
            background-color: var(--theme-two);
            color: white;
          }
        }
      }
    }
  
    #home-box-nine {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      padding: 2rem 1.5rem 0 1.5rem;
      box-sizing: border-box;
      #home-box-nine-mid {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        margin: unset;
        box-sizing: border-box;
        #home-box-nine-mid-left {
          position: relative;
          height: auto;
          width: 100%;
          transform: translateX(-100%);
          transition: transform 1s ease-in-out;
          animation-name: nine-left;
          animation-duration: 1s;
          animation-fill-mode: both;
          box-sizing: border-box;

          #home-box-nine-mid-title {
            height: 4rem;
            margin-top: 0rem;
            font-size: 2.5rem;
            text-align: center;
            font-family: fontBold;
            color: var(--theme-three);
          }

          #home-box-nine-mid-subtitle {
            height: auto;
            padding: 3rem 0rem;
            font-size: 1.15rem;
            line-height: 1.6rem;
            letter-spacing: 0.3px;
            word-spacing: 3px;
          }

          #home-box-nine-mid-btn {
            width: fit-content;
            padding: 0.75rem 1.5rem;
            margin: -1rem 0 4rem 0;
            box-shadow: 3px 3px 10px 0 var(--shadow-two);
            border-radius: 0.5rem;
            cursor: pointer;
            background-color: var(--bg);
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
            &:hover {
              background-color: var(--theme-two);
              color: white;
            }
          }

          #home-box-nine-mid-left-img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30rem;
            width: 100%;
            transform: translateX(200%);
            transition: transform 1s ease-in-out;
            padding: 1rem 0 0 0rem;
            animation-name: nine-right;
            animation-duration: 1s;
            animation-fill-mode: both;
            box-sizing: border-box;
            
            #home-box-nine-img-relative {
              display: flex;
              justify-content: center;
              position: relative;
              .home-box-nine-mid-img {
                height: 25rem;
                width: 20rem;
                border-radius: 0.5rem;
                background-image: url('../../../Images/Interior/303.jpg');
                background-size: cover;
                background-position: center;
              }
              @media screen and (max-width: 390px) {
                #about-image-two-phone {
                  left: 20%;
                }
              }
            }
          }
        }
        @keyframes nine-left {
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
        @keyframes nine-right {
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
      #home-box-nine-bottom {
        width: 100%;
        #home-box-nine-bottom-title {
          height: 3.5rem;
          width: 100%;
          margin: auto;
          padding-bottom: 1rem;
          font-size: 2.5rem;
          font-family: fontBold;
          color: var(--theme-two);
        }
        #sis-center {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: auto;
          width: 100%;
          margin: 0 auto;
          box-sizing: border-box;
          .sis-cards {
            display: flex;
            align-items: center;
            height: 11rem;
            width: 100%;
            padding: 1rem;
            background-color: var(--bg);
            border-radius: 0.75rem;
            box-shadow: 2px 2px 10px 0 #ddd;
            box-sizing: border-box;
            .sis-cards-left {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              height: 100%;
              width: 8rem;
              padding-top: 2rem;
              .sis-cards-left-logo {
                height: 6rem;
                width: 6rem;
              }
            }
            .sis-cards-right {
              height: 90%;
              width: auto;
              margin-left: 1rem;
              .sis-cards-right-title {
                font-size: 1.5rem;
                font-family: fontBold;
                color: var(--theme-two);
              }
              .sis-cards-right-subtitle {
                margin-top: 0.5rem;
                font-size: 1rem;
              }
              .sis-cards-right-link {
                margin-top: 0.5rem;
                a {
                  text-decoration: none;
                  color: #000;
                  font-weight: bolder;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
            &:last-child {
              margin: 1.5rem 0 0 0;
            }
          }
        }
      }
  
    }
  
    #home-box-ten {
      position: relative;
      height: auto;
      width: 100%;
      background-size: cover;
      background-position: center;
  
      #home-box-ten-top {
        display: block;
        height: auto;
        width: 90%;
        margin: 0 auto;
        padding: 0.5rem 0 0.5rem 0;
        box-sizing: border-box;
        #home-box-ten-top-one {
          box-sizing: border-box;
          #home-box-ten-top-one-title {
            text-align: center;
            font-size: 2.5rem;
            font-family: fontBold;
          }
        }
      }
      #home-box-ten-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        margin: 1.5rem 0 0 0;
        padding-bottom: 0;
        margin-bottom: -2rem;
        background-size: cover;
        .home-box-ten-indi {
          position: relative;
          height: 25rem;
          width: 18rem;
          margin: 1rem;
          border-radius: 0.5rem;
          box-sizing: border-box;
          background-size: cover;
          background-position: center;
          box-shadow: 3px 3px 15px 0 #ccc;
          &::after {
            position: absolute;
            bottom: 40%;
            left: 0;
            height: 0;
            width: 100%;
            color: white;
            opacity: 0;
            padding: 1rem;
            border-radius: 0.5rem;
            background-image: linear-gradient(to bottom, black, transparent);
            box-sizing: border-box;
            transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out, bottom 0.5s ease-in-out;
          }
          &:nth-child(1) {
            background-image: url("../../../Images/Management/six.jpeg");
            &::after {
              content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
            }
          }
          &:nth-child(2) {
            background-image: url("../../../Images/Management/seven.jpeg");
            &::after {
              content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
            }
          }
          &:nth-child(1) {
            background-image: url("../../../Images/Management/one.jpg");
            &::after {
              content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
            }
          }
          &:nth-child(2) {
            background-image: url("../../../Images/Management/four.jpg");
            &::after {
              content: "We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.";
            }
          }
          .home-box-ten-indi-bottom {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
            position: absolute;
            bottom: 0;
            height: 60%;
            width: 100%;
            border-radius: 0.5rem;
            background-image: linear-gradient(to top, black, transparent);
            padding: 1rem;
            box-sizing: border-box;
            color: white;
            .home-box-ten-indi-bottom-title {
              font-size: 1.4rem;
              font-weight: 900;
            }
            .home-box-ten-indi-bottom-subtitle {
              font-size: 1.1rem;
            }
          }
        }      
        #ten-bottom-one {
          padding: 0 0 0 0;
        }
        #ten-bottom-two {
          display: none;
        }
        #ten-bottom-three {
          padding: 0 0 3rem 0;
        }
      }
      #home-box-ten-phone-btn-box {
        width: 100%;
        padding: 5rem 0 0rem 0;
        #home-box-ten-phone-btn {
          width: fit-content;
          padding: 1rem 1.75rem;
          margin: 0 auto;
          box-shadow: 3px 3px 10px 0 #ccc;
          border-radius: 0.5rem;
          background-color: #fff;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          &:hover {
            background-color: var(--theme-three);
            color: white;
          }
        }
      }
    }
  } 

}




//home banner //


.slide{
  height: 150px;
  width: 200px;
  display: grid;
  place-items: center;
  padding: 15px;
  perspective: 100px;
}

.banner_slide_img{
  height: 100%;
  width:100%;
  transition: transform 1s;
}
.image-slider{
  display: grid;
  place-items: center;
  height: 737px;
  position: relative;
  margin-top: -657px;
  overflow: hidden;
  // height: 250px;  
  width: 100%;
  background-image: url('../../../Assets/banner/apc_banner.png');
  background-size: cover;
  overflow: hidden;
  grid-template-rows: 60% 40%;

}
.image-slider-track {
  display: flex;
  width: 2400px;
  animation: play 40s linear infinite;
  margin-top: -151px;
  gap: 46px;
  // margin-bottom: 178px;
}

.home-five-indi-box {
  /* Set the initial scale to 1 */
  transform: scale(1);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  /* Other styles... */
  cursor: pointer;
}

.home-five-indi-box:hover {
  /* Increase the scale to zoom in on hover */
  transform: scale(1.1); /* Adjust the scale value as needed for the desired zoom level */
  /* Add other hover styles if needed */
}


#home-container #home-box-seven #home-box-seven-btn{
  border: 1px solid #00000033;
}



@keyframes play{
  0%{
      transform: translateX(0);
  }

  100%{
      transform: translateX(calc(-250px*6));
  }
}

.image-slider::before,
.image-slider::after{
  content: '';
  height: 100%;
  width: 15%;
  z-index: 2;
  position: absolute;

  }

  .image-slider::before{
      left:0;
      top:0;
  }

  .image-slider::after{
      right:0;
      top:0;

  }

  // img:hover{
  //     transform: translateZ(20px);
  // }

  
  .text-after {
    position: absolute;
    bottom: 10px;
    left: 10px; 
    color: white; 
    background-color: rgba(0, 0, 0, 0.5); 
    padding: 5px 10px; 
    font-size: 16px;
    border-radius: 5px;
  }
  

  .slide {
    position: relative;
  }
  
  .image-container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  .banner_slide_img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 16px;
  }
  
  // .banner-overlay {
  //   position: absolute;
  //   top: 102px;
  //   left: 1px;
  //   width: 100%;
  //   height: 100%;
  //   background-image: linear-gradient(to top, #6969b3, transparent);
  //   // display: grid;
  //   // justify-content: center;
  //   align-items: center;
  //   text-align: center;
  //   border-radius: 16px;
  // }

  .banner-overlay p {
    font-size: 17px;
    display: flex;
    margin-top: 137px;
    font-weight: 600;
    color: white;
    margin: 199px 0px 5px 10px;
  }  

.banner-overlay h3{
  margin-top: -11px;
  margin-bottom: 0px;
  display: flex;
  color: white;
  font-weight: 100;
  font-size: 15px;
  margin: 0px 0px 0px 10px;
}
  // .banner-overlay p {
  //   text-align: start;
  //   font-size: 19px;
  //   margin: 123px 0px 0px 10px;
  //   color: white;
  //   font-weight: 700;
  //   }

  //   .banner-overlay h4{
  //     margin: 8px 0px 19px 10px;
  //     text-align: start;
  //     color: white;
  //     font-weight: 100;
  //   }
  
#inc-container #inc-top-box .inc-top-left {
  top: 96px;
}


#inc-container #inc-bottom-box {
  margin-top: 97px;
}

#dr-container {
  margin-top: 96px;
}

#health-container {
  margin-top: 115px;
}



.slide {
  cursor: pointer;
  transition: transform 0.3s ease; 
}

.slide:hover {
  transform: scale(1.1); 
}


.banner_heading {
  margin: 0px 51% 0px 0px;
}

.banner_heading h3{
  font-size: 65px;
  margin-top: 59px;
  margin-bottom: 0px;
  color: var(--theme-three);
}

.banner_heading p{
  font-size: 29px;
  margin-bottom: opx;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--theme-three);
}

.banner_heading {
  width: 57%;
  margin: 0px 65% 0px 36%;
}
// .management_section{
//   background-image:url('../../../Assets//management/management-gradiant.png');
// }

#home-container #home-box-ten {
  background-image:url('../../../Assets//management/management-gradiant.png');
}

#home-container #home-box-ten #home-box-ten-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.management_logo h4{
  font-size: 31px;
  margin-top: 0px;
  margin-bottom: auto;
  color: var(--theme-three);
}

#home-box-ten-top-two{
  display: flex;
  justify-content: end;
  margin: 0px 132px 0px 0px;
}

// #home-box-ten-top-two{
//   display: flex;
//   justify-content: end;
//     width: fit-content;
//     padding: 0.75rem 1.5rem;
//     box-shadow: 3px 3px 10px 0 var(--shadow-two);
//     border-radius: 0.5rem;
//     cursor: pointer;
//     background-color: var(--bg);
//     transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
// }


.management_logo img{
  width: 100%;
}


#home-container #home-box-ten #home-box-ten-bottom .home-box-ten-indi{
  box-shadow: 3px 3px 15px 0 #6969b3;
}

#home-container #home-box-ten #home-box-ten-bottom {
  text-align: start;
}

.management_content h4{
  font-size: 42px;
  text-align: center;
  margin-top: 90px;
  color: #6969b3;
}

.management_content p{
    width: 74%;
    margin-left: auto;
    margin-right: auto;
    line-height: 29px;
    text-align: center;
}

.management_content{
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: #6969b3 0px 7px 29px 0px;
}


#home-container #home-box-ten {
  position: relative;
  height: auto;
  background-color: #eee;
  background-size: cover;
  padding: 10rem 0;
}


#home-box-ten-top-two {
  // margin-left: auto;
  width: fit-content;
  display: flex;
  margin: -55px 132px 0px auto;
  background-color: white;
  padding: 9px;
  border-radius: 7px;
  cursor: pointer;
}

#home-box-ten-top-two-btn{
  width: 77px;
  text-align: center;
}

#home-box-ten-top-two:hover{
background-color: var(--theme-three);
color: white;
}



//new animation //

.scroller {
  max-width: 55%;
  margin-right: auto;
  margin-left: 110px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap:20px;
  height: 290px;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 30s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}
.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}


@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.scroll-img, .scroll-img img {
  width: 210px;
  height: 275px;
  border-radius: 10px;
}


.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  background: var(--clr-primary-400);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

/* for testing purposed to ensure the animation lined up correctly */
.test {
  background: red !important;
}






.scroll-img {
  position: relative;
}

.image-container {
  position: relative;
  display: inline-block;
}

// img {
//   width: 100%;
//   height: auto;
// }

.banner-overlay {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #6969b3, rgba(255, 255, 255, 0));
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overlay-text {
  font-size: 1.5em;
}

.overlay-subtext {
  font-size: 1em;
}




.banner_heading {
  opacity: 0; /* Start with zero opacity */
  transition: opacity 0.5s ease-in-out; /* Apply a smooth transition */
}

.banner_heading.show {
  opacity: 1; /* When the "show" class is applied, fade in */
}




@media(max-width:500px){
  .image-slider {
    margin-top: -456px;
    // background-image: url('https://tse4.mm.bing.net/th?id=OIP.6uCaou5nkNbNRtW4OglovAHaEK&pid=Api&P=0&h=180');
  }

  .scroll-img, .scroll-img img {
    width: 164px;
    height: 209px;
    border-radius: 10px;
}

  .banner-overlay p {
    font-size: 16px;
    display: flex;
    margin-top: 137px;
    font-weight: 600;
    color: white;
    margin: 146px 0px 4px 10px
  }

  .scroller {
    max-width: 90%;
    margin-right: auto;
    margin-left: 21px;
    height: 225px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.7rem;
  height: 181px;
}

  .image-slider-track {
    display: flex;
    width: 2400px;
    animation: play 40s linear infinite;
    margin-top: -94px;
  }

  .banner_heading {
    margin: 46px 65% 12px 119px !important;
}

  #home-container #home-box-five #home-box-five-container{
  margin-top: 100px;
  }
  #home-container #home-box-seven #home-box-seven-title {
    margin: 27 auto;
    padding: 4.5rem 0 0 0;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.9rem;
    font-family: fontBold;
    color: #6969b3;
  }


  .image-slider{
    background-size: contain;
    background-position: right;
    overflow: hidden;
    grid-template-rows: 60% 40%;
    background-repeat: no-repeat;
    background-color: white;
  }
  .image-slider-track {
    gap: 11px;
  }

  .banner_heading p {
    font-size: 19px;
    width: 70%;
    color: #51519d;
}

  .banner_heading {
    width: 100%;
    margin: 226px 0px 0px 37px !important;
  }
.banner_heading h3 {
  font-size: 21px;
  width: 49%;
}
.management_logo img {
  width: 90%;
}
#home-container #home-box-ten #home-box-ten-top #home-box-ten-top-one #home-box-ten-top-one-title {
  text-align: center;
  font-size: 33px;
}
.management_content{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 33px;
  margin-bottom: 33px;
}
.management_content h4 {
  font-size: 30px;
  text-align: center;
  margin-top: 40px;
}
.management_content p {
  font-size: 16px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  line-height: 26px;
  margin-top: -30px;
  text-align: center;
}


}

@media(min-width:438px) and (max-width:768px){
  .image-slider {
    margin-top: -323px;
  }
  .scroller {
    max-width: 91%;
    margin-right: auto;
    margin-left: 26px;
}
.banner_heading {
  margin: 97px 65% 0px 72%;
  width: 97%;
}
#home-container #home-box-ten #home-box-ten-bottom #ten-bottom-one {
  padding: 0 0 0 0;
  display: flex;
}
.management_content {
  width: 90%;
}
.management_content p {
  font-size: 16px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
#home-container #home-box-ten #home-box-ten-bottom #ten-bottom-three {
  padding: 0 0 3rem 0;
  display: flex;
}
#home-container #home-box-ten {
  padding: 5rem 0;
}
}

@media(min-width:779px) and (max-width:1042px){
  .image-slider {
    margin-top: -308px;
  }
  .banner_heading {
    width: 89%;
    margin: 15% 65% 0px 83% !important;
}
#header-container #header-one-default {
padding: 0px;
}
#header-container .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  min-width: 4rem;
  /* padding: 1rem; */
  font-size: 11px;
  gap: 35px;
  font-family: fontExBold;
  margin: 0px 1.2rem;
}



}


.scroller {
  position: relative;
  overflow: hidden;
}

.scroller__inner {
  white-space: nowrap;
  animation: scrollLeft 5s linear infinite;
}

.scroller__item {
  display: inline-block;
  position: relative;
  margin-right: 10px; /* Adjust this for spacing between images */
}

.scroller__item img {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the background color and opacity */
  color: white; /* Adjust the text color */
  padding: 10px; /* Adjust the padding as needed */
}

// @keyframes scrollLeft {
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }






@media(min-width:786px) and (max-width:1069px){
  .scroller {
    max-width: 80%;
    margin-right: auto;
    margin-left: 60px;
}
.banner_heading {
  width: 89%;
  margin: 15% 65% 0px 68% !important;
}
#header-container .header-box-one .header-one-left-box-shrink #header-one-left-top-img {
  width: 3rem;
}
#header-container .header-box-one .header-one-left-box-shrink #header-one-left-top::after {
  font-size: 14px;
}
#header-container .header-box-one .header-one-left-box-shrink #header-one-left-top {
  font-weight: 500;
  font-size: 26px;
}
#header-container .header-box-one #header-one-left-box #header-one-left-top::after {
  content: "polyclinic";
  position: absolute;
  left: 2px;
}
#header-container .header-box-one .header-one-left-box-shrink #header-one-left-top::after {
  bottom: 10px;
}
#header-container .header-box-one #header-one-left-box {
  display: flex;
  margin: 0px 0px 0px 25px;
}
#header-container .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu {
  padding: 0px;
}
#header-container .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu {
  margin: 0px;
}
#header-container .header-box-one #header-one-right-box #header-one-right-bottom {
  display: flex;
  gap: 23px;
  margin: 0px 20px 0px 0px;
}
#home-container #home-box-ten #home-box-ten-bottom #ten-bottom-one {
  display:flex;
  gap: 70px;
  margin-bottom: 20px;
}
.management_content {
  width: 90%;
  margin-left: auto;
  margin-right: auto
}
#home-container #home-box-ten #home-box-ten-bottom #ten-bottom-three{
  display: flex;
  gap: 70px;
  margin-top: 20px;
}
#home-container #home-box-five #home-box-five-container {
  width: 47%;
  margin-left: auto;
  margin-right: auto;
}
}

.home-button-size-ar {
  font-size: 1.3rem;
}

.home-box-common-title-en {font-size: 3rem;}
.home-box-common-title-ar {font-size: 4rem;}

.home-box-common-subtitle-en {font-size: 1.1rem;}
.home-box-common-subtitle-ar {font-size: 1.5rem;}

$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);

$shadow: rgb(191, 186, 206);
$shadow-two:  rgb(168, 164, 180);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#abt-container {
  height: auto;
  width: 100%;
  // margin-top: 9rem;
  // padding: 2rem 13rem;
  background-color: $bg;
  box-sizing: border-box;

  #abt-header-cover {
    display: none;
  }

  #abt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-image: url("../../../Images/Waves/six.svg");
    background-color: $bg;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    #abt-mid {
      position: relative;
      display: flex;
      height: auto;
      width: 83%;
      margin: 2rem 0 0rem 0;
      box-sizing: border-box;

      #abt-mid-left {
        position: relative;
        transform: translateX(120%);
        height: auto;
        width: 50%;
        transition: transform 2s ease-in-out;
        animation-name: nine-left;
        animation-duration: 2s;
        animation-fill-mode: both;
        box-sizing: border-box;

        #abt-mid-title {
          height: 3.5rem;
          margin-top: 2rem;
          font-size: 3rem;
          font-family: fontBold;
          color: $theme-three;
        }
        #abt-mid-subtitle {
          height: fit-content;
          padding: 3rem 0;
          // font-family: fontRaleLight;
          font-size: 1.2rem;
          line-height: 1.7rem;
          letter-spacing: 0.5px;
          word-spacing: 3px;
          text-align: justify;
        }
        #abt-mid-btn {
          width: fit-content;
          padding: 0.75rem 1.5rem;
          box-shadow: 3px 3px 10px 0 $shadow-two;
          border-radius: 0.5rem;
          cursor: pointer;
          background-color: $bg;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

          &:hover {
            background-color: $theme-three;
            color: white;
          }
        }
      }
      #abt-mid-right {
        position: relative;
        height: 100%;
        width: 50%;
        padding: 2.5rem 0rem 1rem 0;
        transform: translateX(-120%);
        transition: transform 2s ease-in-out;
        animation-name: nine-right;
        animation-duration: 2s;
        animation-fill-mode: both;
        box-sizing: border-box;
        // background-color: red;

        .abt-mid-right-img {
          height: 35rem;
          width: 37rem;
          border-radius: 0.5rem;
          background-image: url('../../../Images/Interior/303.jpg');
          background-size: cover;
          background-position: center;
        }
      }
      @keyframes nine-left {
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      @keyframes nine-right {
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
    #abt-bottom {
      width: 100%;
      padding-bottom: 4rem;

      #abt-bottom-title {
        height: 3.5rem;
        width: 83.5%;
        margin: auto;
        padding-bottom: 1rem;
        font-size: 2.5rem;
        font-family: fontBold;
        color: $theme-three;
      }

      #sis-center {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5rem;
        height: auto;
        width: 83.5%;
        margin: 0 auto;
        box-sizing: border-box;

        .sis-cards {
          display: flex;
          align-items: center;
          height: 10rem;
          width: 45%;
          padding: 1rem;
          background-color: $bg;
          border-radius: 0.75rem;
          box-shadow: 2px 2px 10px 0 #ddd;
          box-sizing: border-box;

          .sis-cards-left {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 8rem;

            .sis-cards-left-logo {
              height: 6rem;
              width: 6rem;
              background-image: url("../../../Images/Header/logo1B.svg");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          .sis-cards-right {
            height: 6rem;
            width: auto;
            margin-left: 1rem;

            .sis-cards-right-title {
              font-size: 1.5rem;
              font-family: fontBold;
              color: $theme-three;
            }
            .sis-cards-right-subtitle {
              margin-top: 0.5rem;
              font-size: 1.1rem;
            }
            .sis-cards-right-link {
              font-size: 1.1rem;
              margin-top: 0.5rem;

              a {
                text-decoration: none;
                color: #000;
                font-weight: bolder;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          // &:last-child {
          //   margin-left: 4rem;
          // }
        }
      }
    }
  }  

  #abt-bottom-box-title {
    height: 3.5rem;
    width: 83.5%;
    margin: auto;
    padding-bottom: 1rem;
    font-size: 2.5rem;
    font-family: fontBold;
    color: $theme-three;
  }

  #abt-bottom-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 84.5%;
    margin: 0 auto;
    padding-bottom: 3rem;
    // margin-top: 2rem;
    // background-color: red;
    

    .abt-bottom-indi {
      position: relative;
      height: 23rem;
      width: 16rem;
      margin: 0 1rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      box-shadow: 3px 3px 15px 0 #ccc;
      // box-shadow: 3px 3px 15px 0 #101010;

      &::after {
        position: absolute;
        bottom: 40%;
        left: 0;
        height: 0;
        width: 100%;
        color: white;
        opacity: 0;
        padding: 1rem;
        border-radius: 0.5rem;
        background-image: linear-gradient(to bottom, black, transparent);
        box-sizing: border-box;
        transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out, bottom 0.5s ease-in-out;
      }

      // &:hover {
      //   &::after {
      //     height: 100%;
      //     opacity: 1;
      //     bottom: 0%;
      //   }
      // }

      .abt-bottom-indi-bottom {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        height: 60%;
        width: 100%;
        border-radius: 0.5rem;
        background-image: linear-gradient(to top, black, transparent);
        padding: 1rem;
        box-sizing: border-box;
        color: white;

        .abt-bottom-indi-bottom-title {
          font-size: 1.4rem;
          font-weight: 900;
        }
        .abt-bottom-indi-bottom-subtitle {
          font-size: 1.1rem;
        }
      }
    }

    #abt-bottom-one {
      padding: 2rem 0;

      .abt-bottom-indi {
        &:nth-child(1) {
          background-image: url('../../../Images/Management/six.jpeg');
          background-position: -5px -5px;
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
          margin-bottom: 2rem;
        }
        &:nth-child(2) {
          background-image: url('../../../Images/Management/seven.jpeg');
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
        }
      }
    }

    #abt-bottom-two {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 22rem;
      width: 17rem;
      font-size: 5.5rem;
      line-height: 5rem;
      background-image: url("../../../Images/Header/logo1B.svg");
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      text-align: center;
      letter-spacing: 0.5rem;
      font-family: asianFont;
      color: $theme-three;
      margin: 0;
      padding-left: 10px;
    }

    #abt-bottom-three {
      padding: 2rem 0;

      .abt-bottom-indi {
        &:nth-child(1) {
          background-image: url('../../../Images/Management/one.jpg');
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
          margin-bottom: 2rem;
        }
        &:nth-child(2) {
          background-image: url('../../../Images/Management/four.jpg');
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  #abt-container {
    position: relative;
    height: auto;
    padding: 2rem 0rem 2rem 0rem;
    margin-top: 0rem;

    #abt-header-cover {
      display: block;
      position: absolute;
      top: -4.5rem; left: 0;
      height: 4.5rem;
      width: 100%;
      background-color: $theme-three;
      box-sizing: border-box;
    }

    #abt {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      padding: 2rem 1.5rem 0 1.5rem;
      box-sizing: border-box;
      // background-color: red;

      #abt-mid {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        margin: unset;
        box-sizing: border-box;

        #abt-mid-left {
          position: relative;
          height: auto;
          width: 100%;
          transform: translateX(-100%);
          transition: transform 1s ease-in-out;
          animation-name: nine-left;
          animation-duration: 1s;
          animation-fill-mode: both;
          box-sizing: border-box;

          #abt-mid-title {
            height: 4rem;
            margin-top: 0rem;
            font-size: 2.5rem;
            font-family: fontBold;
            color: $theme-three;
            text-align: center;
          }

          #abt-mid-subtitle {
            height: auto;
            padding: 3rem 0rem;
            font-size: 1.15rem;
            line-height: 1.6rem;
            letter-spacing: 0.3px;
            word-spacing: 3px;
          }

          #abt-mid-left-img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30rem;
            width: 100%;
            transform: translateX(200%);
            transition: transform 1s ease-in-out;
            padding: 1rem 0 0 0rem;
            animation-name: nine-right;
            animation-duration: 1s;
            animation-fill-mode: both;
            box-sizing: border-box;

            #abt-img-relative {
              position: relative;

              .abt-mid-img {
                height: 25rem;
                width: 20rem;
                border-radius: 0.5rem;
                background-image: url('../../../Images/Interior/303.jpg');
                background-size: cover;
                background-position: center;
              }
              @media screen and (max-width: 390px) {
                #about-image-two-phone {
                  left: 20%;
                }
              }
            }
          }
        }
        @keyframes nine-left {
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
        @keyframes nine-right {
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      #abt-bottom {
        width: 100%;

        #abt-bottom-title {
          height: 3.5rem;
          width: 100%;
          margin: auto;
          padding-bottom: 1rem;
          font-size: 2.5rem;
          font-family: fontBold;
          color: $theme-three;
          text-align: center;
        }

        #sis-center {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: auto;
          width: 100%;
          margin: 0 auto;
          box-sizing: border-box;

          .sis-cards {
            display: flex;
            align-items: center;
            height: 11rem;
            width: 100%;
            padding: 1rem;
            background-color: $bg;
            border-radius: 0.75rem;
            box-shadow: 2px 2px 10px 0 #ddd;
            box-sizing: border-box;

            .sis-cards-left {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              height: 100%;
              width: 8rem;
              padding-top: 2rem;

              .sis-cards-left-logo {
                height: 6rem;
                width: 6rem;
              }
            }

            .sis-cards-right {
              height: 90%;
              width: auto;
              margin-left: 1rem;

              .sis-cards-right-title {
                font-size: 1.5rem;
                font-family: fontBold;
                color: $theme-three;
              }
              .sis-cards-right-subtitle {
                margin-top: 0.5rem;
                font-size: 1.1rem;
              }
              .sis-cards-right-link {
                font-size: 1.1rem;
                margin-top: 0.5rem;

                a {
                  text-decoration: none;
                  color: #000;
                  font-weight: bolder;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            &:last-child {
              margin: 1.5rem 0 0 0;
            }
          }
        }
      }
    }

    #abt-bottom-box-title {
      width: 90%;
      text-align: center;
    }

    #abt-bottom-box {
      flex-direction: column;
      justify-content: center;

      #abt-bottom-one {
        padding: 2rem 0 0 0;
      }
      
      #abt-bottom-two {
        display: none;
      }

      #abt-bottom-three {
        padding: 2rem 0 3rem 0;
      }
    }
  }
}


#abt-container #abt #abt-mid {
  margin: 10rem 0 0rem 0;
}


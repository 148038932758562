$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);

$shadow: rgb(191, 186, 206);
$shadow-two:  rgb(168, 164, 180);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#gal-container {
  height: auto;
  width: 100%;
  margin-top: 127px;
  padding: 2rem 10rem 4rem 10rem;
  background-color: $bg;
  box-sizing: border-box;

  #gal-header-cover {
    display: none;
  }

  .gal-title {
    font-size: 2.5rem;
    font-family: fontBold;
    color: $theme-three;
    margin-bottom: 2rem;

    &:nth-child(4) {
      margin-top: 5rem;
      text-align: center;
    }
  }

  .gal-cont {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
    width: 100%;

    .gal-col {
      position: relative;
      height: 100%;
      width: 32.5%;

      .gal-img {
        height: auto;
        width: 100%;
        object-fit: cover;
        object-position: center;

        &:not(&:last-child) {margin-bottom: 0.75rem;}
      }
    }
  }

}


@media screen and (max-width: 1024px) {
  #gal-container {
    position: relative;
    height: auto;
    padding: 2rem 0rem 2rem 0rem;
    margin-top: 0rem;

    #gal-header-cover {
      display: block;
      position: absolute;
      top: -4.5rem; left: 0;
      height: 4.5rem;
      width: 100%;
      background-color: $theme-three;
      box-sizing: border-box;
    }

    .gal-title {
      font-size: 2.5rem;
      font-family: fontBold;
      color: $theme-three;
      margin: 0 0 2rem 1rem;
    }

    .gal-cont {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      
      .gal-col {
        width: 93%;
        &:not(&:last-child) {margin-bottom: 0.5rem;}
        
        .gal-img {
          &:not(&:last-child) {margin-bottom: 0.5rem;}
        }
      }
    }
  }
}
































// .gal-cont {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   height: 25rem;
  //   width: 100%;
  //   // padding: 0 0.5rem;
  //   margin-bottom: 3rem;
  //   box-sizing: border-box;

  //   .gal-box {
  //     position: relative;
  //     height: 25rem;
  //     width: 45%;
  //     border-radius: 0.5rem;
  //     box-shadow: 2px 2px 10px 0 $shadow;
  //     box-sizing: border-box;
  //     object-fit: cover;
  //     object-position: center;

  //     .gal-img {
  //       height: 100%;
  //       width: 100%;
  //       border-radius: 0.5rem;
  //     }
      
  //     .gal-box-des {
  //       position: absolute;
  //       left: 0; bottom: 0;
  //       height: 5rem;
  //       width: 100%;
  //       border-radius: 0 0 0.5rem 0.5rem;
  //       background-color: $footer-one;
  //       // background-image:linear-gradient(to top, $footer-one,  transparent);
  //     }
  //   }
  // }